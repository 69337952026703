import AgencyForm from "../pages/AgencyForm/AgencyForm";
import HomePage from "../pages/HomePage/HomePage";

const publicRoutes: Routes[] = [
  {
    path: "",
    Component: HomePage,
  },
  // {
  //   path: "agency-form",
  //   Component: AgencyForm,
  // },
];

export { publicRoutes };
