import React from "react";

const Footer = () => {
  return (
    <section id="FOOTER">
      <div className="container mx-auto px-3 md:px-0">
        <div className="flex justify-between flex-wrap gap-3">
          <div className="order-2 sm:order-1 flex sm:block justify-between w-full sm:w-auto">
            Copyright © 2023 <strong className="ml-5">LuckyBox</strong>
          </div>
          <div className="order-1 sm:order-2 flex gap-1">
            <button>
              <a href="https://getluckytoday.io/terms-and-conditions">
                Terms & Condition
              </a>
            </button>
            |
            <button>
              <a href="https://getluckytoday.io/privacy-policy">
                Privacy Policy
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="bg-footer"></div>
    </section>
  );
};

export default Footer;
