import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { publicRoutes } from "../src/routes/routes";
import DefaultLayout from "./layouts/DefaultLayout.tsx/DefaultLayout";
import { useEffect } from "react";

function App() {
  return (
    <Router>
      <Routes>
        {publicRoutes.map(({ Component, layout, path }, index: number) => {
          let Layout = DefaultLayout;
          if (layout) {
            Layout = layout;
          } else if (layout === null) {
            // FramentLayout
          }
          return (
            <Route
              key={index}
              path={path}
              element={<Layout Children={Component} />}
            ></Route>
          );
        })}
      </Routes>
    </Router>
  );
}

export default App;
