import React from "react";
import {
  pink_circle,
  blue_purple_circle,
  firework,
} from "../../utils/data/data";

const About = () => {
  return (
    <section id="ABOUT" className="relative mb-[250px] md:mb-[500px]">
      <div className="container mx-auto px-3 md:px-0">
        <div className="box relative p-5 md:p-10 flex flex-col gap-5 lg:gap-10 z-20">
          <div className="text-center font-bold text-2xl lg:text-4xl">
            ABOUT
          </div>
          <div className="md:mb-24 lg:mb-36 text-sm sm:text-base lg:text-2xl lg:font-thin text-center sm:text-left">
            We're thrilled to introduce our new webgame, Lucky Number! We
            understand how much a stroke of good luck can change your life, and
            we've made it incredibly easy to play and increase your chances of
            winning big. Say farewell to the traditional games of chance with
            low odds - with Lucky Number, your chances of winning are 100 times
            higher, and the winning ratio is much more favorable. Additionally,
            you can play from the comfort of your own home, which is a huge
            plus! Don't miss out on this incredible opportunity; start playing
            today and see if Lady Luck is on your side!
          </div>
          <img
            src={pink_circle}
            alt="icon"
            className="absolute -bottom-2 md:bottom-[-2rem] w-12  md:w-20 mix-blend-screen opacity-80 float_animation"
          />

          <div className="blur_ball absolute -bottom-12 left-1 w-[150px] h-[150px] float_animation"></div>
        </div>
        <div className="container mx-auto absolute z-[-1] top-0 lg:top-40 flex justify-center w-full">
          <img src={firework} alt="icon" className="bubble_animation" />
        </div>
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
        <div className="ocean2">
          <div className="wave2"></div>
          <div className="wave2"></div>
        </div>
      </div>
    </section>
  );
};

export default About;
