import React from "react";
import { twitter, telegram } from "../../utils/data/data";

const SocialLink = () => {
  return (
    <section id="TWITTER">
      <div className="fixed right-14 sm:right-20 bottom-5 sm:bottom-10 z-50 flex gap-1">
        {/* <a href="https://twitter.com/GetLuckyTodayIO?t=6CLOUiuKv5bK8KkgyV-JjA&s=09">
          <div className={`w-auto p-2 sm:p-4 box animate-pulse`}>
            <img src={twitter} alt="icon" />
          </div>
        </a> */}
        <a href="https://t.me/getluckytodayofficial">
          <div className={`w-auto p-2 sm:p-4 box animate-pulse`}>
            <img src={telegram} alt="icon" />
          </div>
        </a>
      </div>
    </section>
  );
};

export default SocialLink;
