import { useEffect } from "react";
import About from "../../components/About/About";
import Ad from "../../components/Ad/Ad";
import GameFlow from "../../components/GameFlow/GameFlow";
import Rules from "../../components/Rules/Rules";
import ScrollUp from "../../components/ScrollUp/ScrollUp";
import Slider from "../../components/Slider/Slider";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Ad />
      <ScrollUp />
      <div className="container mx-auto px-3 md:px-0">
        <Slider />
        <Rules />
        <GameFlow />
      </div>
      <About />
    </>
  );
};

export default HomePage;
