import React from "react";
import {
  slider,
  mountain,
  ticket,
  slide_mb,
  blue_circle,
} from "../../utils/data/data";

const Slider = () => {
  return (
    <section id="SLIDER">
      <img
        src={slider}
        alt="icon"
        className="absolute left-0 top-0 w-full z-[-1] invisible md:visible"
      />
      <img
        src={slide_mb}
        alt="icon"
        className="absolute left-0 top-24 w-full z-[-1] visible md:invisible "
      />
      <img
        src={ticket}
        alt="icon"
        className="absolute right-[35vw] md:right-[18vw] top-[37vw] md:top-[15vw] h-[15vw] float_animation"
      />

      <div className="BOX flex justify-center md:justify-start md:w-[45%] xl:w-[37%] 2xl:w-[35%] mt-[45vw] md:mt-10 2xl:mt-32">
        <div className="box p-6 flex flex-col gap-5 lg:gap-10">
          <div className="text-xl lg:text-2xl 2xl:text-5xl font-bold text-center md:text-left">
            WELCOME TO THE LUCKY BOX
          </div>
          <div className=" lg:text-lg text-center md:text-left">
            LuckyBox is a decentralized application that enables users to
            participate in a fair and secure random number game. The platform
            redefines the concept of traditional lotteries by using blockchain
            technology, which provides transparency, security, and efficiency.
          </div>
          <div className="flex justify-center md:justify-start">
            <a href="https://getluckytoday.io/">
              <button className="btn_getStarted">
                Get Started with
                <strong className="text-[#FFD15E]"> 1 </strong>
                <strong>(USD)</strong>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
