import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import SocialLink from "../../components/SocialLink/SocialLink";

interface DefaultLayoutProps {
  Children: React.ComponentType;
}

const DefaultLayout = ({ Children }: DefaultLayoutProps) => {
  return (
    <div className="overflow-hidden">
      <SocialLink />
      <Navbar />
      <Children />
      <Footer />
    </div>
  );
};

export default DefaultLayout;
