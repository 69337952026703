import React, { useRef, RefObject, useState, useMemo } from "react";
import useTutorial from "../../hooks/useTutorial";
import { useClickOutSide } from "../../hooks/useClickOutSide";
import { AiOutlineClose } from "react-icons/ai";
import YouTube from "react-youtube";

enum NavEnum {
  MOBILE = "Mobile",
  DESKTOP = "Desktop",
}

enum YoutubeIdEnum {
  Mobile = "NeaQ7Dapujw",
  Desktop = "xO6zCMyENF8",
}
const Tutorial = () => {
  // YouTube video ID
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const [state, setState] = useState(NavEnum.DESKTOP);
  const { isOpen, onClose } = useTutorial();

  const videoId = useMemo(
    () => (isOpen ? YoutubeIdEnum[state] : ""),
    [state, isOpen]
  );

  // YouTube video options
  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      // YouTube video parameters (optional)
      // autoplay: 1,
    },
  };
  const handleVideoReady = (event: any) => {
    // Video is ready
    // console.log("Video is ready:", event.target);
  };

  useClickOutSide(ref, onClose);

  return (
    <section id="TUTORIAL">
      <div
        className={`fixed -top-20 sm:-top-10 lg:top-20 w-full flex items-center justify-center z-50 transition-transform duration-[2s] ${
          isOpen
            ? "translate-y-44 lg:-translate-y-0 scale-100"
            : "translate-y-[100vh] scale-0"
        }
      `}
      >
        <div
          className="container box p-5 flex flex-col gap-5 sm:gap-10 relative"
          ref={ref}
        >
          <button className="absolute right-5" onClick={onClose}>
            <AiOutlineClose size={24} className="cursor-pointer" />
          </button>
          <div className="sm:absolute flex gap-3">
            <button
              onClick={() => setState(NavEnum.DESKTOP)}
              className={`${state === NavEnum.DESKTOP && "active"} btn`}
            >
              Desktop
            </button>
            <button
              onClick={() => setState(NavEnum.MOBILE)}
              className={`${state === NavEnum.MOBILE && "active"} btn`}
            >
              Mobile
            </button>
          </div>
          <div className="text-center text-xl lg:text-3xl font-bold">
            Tutorial
          </div>
          <div className="flex justify-center">
            <div className="h-[30rem] lg:h-[35rem] w-full">
              {isOpen && (
                <YouTube
                  className="h-full"
                  videoId={videoId}
                  opts={opts}
                  loading="lazy"
                  onReady={handleVideoReady}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tutorial;
