import React, { useEffect, useState } from "react";
import {
  diagram1,
  diagram2,
  diagram1_mb,
  diagram2_mb,
  agency_bg,
  agency_bg2,
  left_wing,
  right_wing,
  polygon_logo,
  polygon_logo_mb,
  ob_left,
  ob_center,
  ob_right,
  roadmap,
  circle_effect,
  circle_position,
  circle_group,
  sm_ob_left1,
  sm_ob_left2,
  sm_ob_center1,
  sm_ob_center2,
  roadmap_mb,
} from "../../utils/data/data";
import { useNavigate } from "react-router";
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
const initial = [
  {
    id: 0,
    title: "Team: 10%",
    content: "10% Tokens, 10% Each month after",
    token: "Tokens",
    value: "10,000,000",
    color: "bg-[#882DCF]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 1,
    title: "Exchange Listings: 20%",
    content: "20% Tokens, 10% unlocked each month after",
    token: "Tokens",
    value: "12,000,000",
    color: "bg-[#D26FEB]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 2,
    title: "Strategic Partners & Advisors: 12%",
    content: "5% tokens, 5% unlocked each month after",
    token: "Tokens",
    value: "12,000,000",
    color: "bg-[#EE4492]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 3,
    title: "Staking: 20%",
    content: "Unlocked",
    token: "Tokens",
    value: "20,000,000",
    color: "bg-[#DF1DFF]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 4,
    title: "Company reserves 15%",
    content: "10% Tokens, 10% unlocked each month after",
    token: "Tokens",
    value: "15,000,000",
    color: "bg-[#7C75FF]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 5,
    title: "Marketing: 3%",
    content: "Unlocked as needed",
    token: "Tokens",
    value: "3,000,000",
    color: "bg-[#6EE3C0]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 6,
    title: "Private Round: 5%",
    content: "5% Tokens, 2% vested weekly",
    token: "Tokens",
    value: "5,000,000",
    color: "bg-[#AEE7FF]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 7,
    title: "Premium Exclusive Presale: 10%",
    content: "5% Tokens, 2% vested weekly",
    token: "Tokens",
    value: "10,000,000",
    color: "bg-[#2864FF]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
  {
    id: 8,
    title: "ETC & ETC",
    content: "5% Tokens, 2% vested weekly",
    token: "Tokens",
    value: "10,000,000",
    color: "bg-[#6998FF]",
    isActive: false,
    isAdjacentActive: false,
    isPieChart: false
  },
]
const GameFlow = () => {
  const navigate = useNavigate();
  const [arrCard, setArrCard] = useState(initial);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [chart, setChart] = useState<any>();
  const [pieSeries, setPieSeries] = useState<any>();
  useEffect(() => {
    const chart = am4core.create('chartdiv', am4charts.PieChart);
    const a = document.querySelectorAll('path')
    a[5].classList.add("remove")
    a[4].classList.add("remove")
    const data = [
      {
        country: 'Team: 10%',
        percents: 10,
      },
      {
        country: 'Exchange Listings: 20%',
        percents: 20,
      },
      {
        country: 'Strategic Partners & Advisors: 12%',
        percents: 12,
      },
      {
        country: 'Staking: 20%',
        percents: 20,
      },
      {
        country: 'Company reserves 15%',
        percents: 15,
      },
      {
        country: 'Marketing: 3%',
        percents: 3,
      },
      {
        country: 'Private Round: 5%',
        percents: 5,
      },
      {
        country: 'Premium Exclusive Presale: 10%',
        percents: 10,
      },
      {
        country: 'ETC & ETC',
        percents: 5,
      },
    ];
    chart.data = data;
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'percents';
    pieSeries.dataFields.category = 'country';
    pieSeries.colors.list = [
      am4core.color("rgba(136, 45, 207, 1)"),
      am4core.color("rgba(210, 111, 235, 1)"),
      am4core.color("rgba(238, 68, 146, 1)"),
      am4core.color('rgba(223, 29, 255, 1)'),
      am4core.color('rgba(124, 117, 255, 1)'),
      am4core.color("rgba(110, 227, 192, 1)"),
      am4core.color("rgba(174, 231, 255, 1)"),
      am4core.color("rgba(40, 100, 255, 1)"),
      am4core.color("rgba(105, 152, 255, 1)"),

    ];
    const chartContainer = document.getElementById('chartdiv');
    if (chartContainer) {
      chartContainer.style.overflow = 'visible';
    }
    chart.innerRadius = am4core.percent(90);
    chart.radius = am4core.percent(80);
    pieSeries.slices.template.fillOpacity = 1;
    pieSeries.labels.template.fontSize = 12;
    var hs: any = pieSeries.slices.template.states.getKey("hover");
    hs.properties.scale = 1;
    hs.properties.fillOpacity = 0.5;
    setChart(chart);
    setPieSeries(pieSeries);

    return () => {
      chart.dispose();
    };
  }, []);
  const handleSetActive = (index: any, val: any) => {
    const updatedArrCard = [...arrCard];
    pieSeries.slices.each((slice: any) => {
      slice.animate(
        [
          {
            property: "shiftRadius",
            from: slice.shiftRadius,
            to: 0,
          },
        ],
        800,
        am4core.ease.polyIn3
      );
    });
    const slice = pieSeries.slices.values.find(
      (s: any) => s.dataItem.dataContext.country === val.title
    );
    if (slice && !val.isActive) {
      slice.animate(
        [{
          property: "shiftRadius",
          from: 0,
          to: 0.1,
        }],
        800,
        am4core.ease.polyIn3
      );
    }
    updatedArrCard.forEach((card) => {
      card.isAdjacentActive = false;
    });

    if (updatedArrCard[index].isActive) {
      updatedArrCard[index].isActive = false;
      updatedArrCard[index - 1 > 0 ? index - 1 : 1].isAdjacentActive = false;
      updatedArrCard[index + 1 < 9 ? index + 1 : 8].isAdjacentActive = false;
      setSelectedComponent(null);
    } else {
      updatedArrCard[index].isActive = true;
      updatedArrCard.forEach((card, i) => {
        if (i !== index) {
          card.isActive = false;
        }
      });
      setSelectedComponent(val.title);
    }
    const prevIndex = index > 0 ? index - 1 : null;
    const nextIndex = index < arrCard.length - 1 ? index + 1 : null;
    if (prevIndex !== null) {
      updatedArrCard[prevIndex].isAdjacentActive = updatedArrCard[index].isActive;
    }
    if (nextIndex !== null) {
      updatedArrCard[nextIndex].isAdjacentActive = updatedArrCard[index].isActive;
    }
    setArrCard(updatedArrCard);
  }
  return (
    <section id="GAMEFLOW" className="mt-20 mb-5 sm:mb-10">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col items-center">
          <div className="text-center font-bold text-lg sm:text-2xl lg:text-4xl">
            Random Number Generation Chart
          </div>
          <img src={diagram1} alt="icon" className="hidden md:block" />
          <img src={diagram1_mb} alt="icon" className="block md:hidden" />
        </div>
        <div className="flex flex-col items-center">
          <div className="text-center font-bold text-lg sm:text-2xl lg:text-4xl">
            Virtual Money Allocation Diagram
          </div>
          <img src={diagram2} alt="icon" className="hidden md:block" />
          <img src={diagram2_mb} alt="icon" className="block md:hidden mt-10" />
        </div>
        <div className="flex flex-col items-center">
          <div className="text-center font-bold text-lg sm:text-2xl lg:text-4xl mb-6">
            Roadmap
          </div>
          {/* <img src={roadmap} alt="icon" className="" /> */}
          <img src={roadmap} alt="icon" className="hidden md:block" />
          <img src={roadmap_mb} alt="icon" className="block md:hidden" />
        </div>
        <div className="text-center font-bold text-lg sm:text-2xl lg:text-4xl mb-6">
          Tokenomics
        </div>
        <div className="flex flex-row max-md:flex-col md:flex-col xl:flex-row">
          <div className="w-1/2 h-[600px] max-md:w-full max-md:h-[300px] md:w-full ">
            <div className="flex gap-[30px] mb-[74px] md:mb-[0px] ml-4 max-md:hidden">
              <p className="text-base font-bold">Total Supply: </p>
              <p className="text-base font-normal">100,000,000</p>
            </div>
            <div className="relative w-full h-full">
              <div id="chartdiv" className=" w-full h-full" >
              </div>
              <div className="max-md:w-[165px] max-md:h-[165px] w-96 h-96 rounded-full border-2 border-dashed border-white absolute left-1/2 bottom-1/2 translate-y-2/4 -translate-x-2/4">
                <img src={circle_effect} alt="" className="w-50 " />
              </div>
            </div>
          </div>
          <div className="w-1/2 max-md:w-full md:w-full md:mt-[30px] relative">
            <img src={circle_group} alt="" className="w-50 absolute bottom-[60px]  right-[30px] max-xl:left-1/2 max-xl:-translate-x-2/4  max-xl:top-1/2 max-xl:-translate-y-2/4" />
            <img src={circle_position} alt="" className="w-50 absolute left-1/2 -translate-x-2/4 top-[50px]" />
            <div className="w-full flex flex-col items-center">
              {arrCard?.map((val: any, index: any) => {
                return (
                  <div
                    className={`card mb-4 flex max-sm:w-[288px] max-sm:h-[96px] ${val.isActive
                      ? 'active-card' : val.isAdjacentActive ? 'card-with' : ""}`}
                    onClick={() => handleSetActive(index, val)}
                  >
                    <div className={`line my-[6px]  ${val.color} max-sm:h-[80px]`}></div>
                    <div className="w-full ml-[14px] mt-2 max-md:hidden">
                      <div className="flex items-center justify-between w-full mb-2 max-md:flex-col max-md:items-start">
                        <p className="text-base font-bold max-md:text-[13px]">{val.title}</p>
                        <p className="text-base font-bold max-md:text-[13px]">Tokens</p>
                      </div>
                      <div className="flex items-center justify-between w-full  max-md:items-start">
                        <p className="text-base font-normal max-md:text-[13px]">{val.content}</p>
                        <p className="text-base font-normal max-md:text-[13px]">{val.value}</p>
                      </div>
                    </div>
                    <div className="max-md:w-full max-md:ml-[16px] max-md:mt-1 md:hidden">
                      <p className=" max-md:font-bold max-md:text-[13px]">{val.title}</p>
                      <p className=" max-md:font-normal max-md:text-[13px] max-md:mt-[4px] max-md:mb-[4px]">{val.content}</p>
                      <div className="max-md:flex max-md:items-center max-md:gap-[5.5px]">
                        <p className=" max-md:font-bold max-md:text-[13px]">Tokens</p>
                        <p className=" max-md:font-normal max-md:text-[13px]">{val.value}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>
        </div>
        <section
          id="POLYGON_LOGO"
          className="flex flex-col items-center justify-center"
        >
          <div className="bg-[#0a090d80] rounded-2xl px-10 sm:px-20 py-3 h-14 sm:h-16 mb-10">
            <img src={polygon_logo} alt="icon" className="h-full" />
          </div>
          <div
            id="POLYGON_DESKTOP"
            className="hidden md:flex justify-between w-full"
          >
            <div className="w-1/3 text-lg relative font-bold flex flex-col justify-between items-center">
              <img src={ob_left} alt="icon" />
              Polygon PoS
            </div>
            <div className="w-1/3 text-lg relative font-bold flex flex-col justify-between items-center">
              <img src={ob_center} alt="icon" />
              Polygon zkEVM
              <img
                src={sm_ob_center1}
                alt="icon"
                className="absolute top-0 -left-[20%] w-[60%] float_animation"
              />
              <img
                src={sm_ob_center2}
                alt="icon"
                className="absolute bottom-[10%] left-0 w-[60%] bubble_animation"
              />
            </div>
            <div className="w-1/3 text-lg relative font-bold flex flex-col justify-between items-center">
              <img src={ob_right} alt="icon" />
              Polygon Miden
              <img
                src={sm_ob_left1}
                alt="icon"
                className="absolute top-[10%] left-[20%] w-1/4 float_animation"
              />
              <img
                src={sm_ob_left2}
                alt="icon"
                className="absolute bottom-[40%] left-[5%] w-1/3 bubble_animation"
              />
            </div>
          </div>
          <div id="POLYGON_MOBILE" className="block md:hidden">
            <img src={polygon_logo_mb} alt="icon" />
          </div>
        </section>
        <section id="AGENCY_BUTTON" className="relative ">
          <div className="box_agency py-6 sm:py-10 md:py-20 overflow-hidden">
            <div className="flex justify-center">
              <a
                // onClick={() => navigate("agency-form")}
                href="https://getluckytoday.io/"
                className="btn_registerAgency font-bold relative"
              >
                Become Our Agency By Inviting Friends
                <img
                  src={left_wing}
                  alt="icon"
                  className="absolute -left-10 sm:-left-28 -top-0 sm:-top-7 lg:-top-5 w-20 sm:w-36 mix-blend-color-dodge"
                />
                <img
                  src={right_wing}
                  alt="icon"
                  className="absolute -right-10 sm:-right-28 -top-0 sm:-top-7 lg:-top-5 w-20 sm:w-36 mix-blend-color-dodge"
                />
              </a>
            </div>
            <img
              src={agency_bg}
              alt="bg"
              className="absolute top-0 flex justify-center w-full h-full -z-[1] mix-blend-color-dodge"
            />
            <img
              src={agency_bg2}
              alt="bg"
              className="absolute top-0 left-1 sm:left-4 flex justify-center w-full h-full -z-[1] mix-blend-color-dodge"
            />
          </div>
        </section>
      </div>
    </section>
  );
};

export default GameFlow;
