import React, { useRef, RefObject, useEffect, useState } from "react";
import { useClickOutSide } from "../../hooks/useClickOutSide";
import { AiOutlineClose } from "react-icons/ai";
import useAd from "../../hooks/useAd";
import {
  Ad_banner,
  banner_animation,
  Ad_banner_mb,
} from "../../utils/data/data";
import useTutorial from "../../hooks/useTutorial";
import Lottie from "react-lottie";

const Ad = () => {
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const { isOpen, onClose, onOpen } = useAd();
  const { isOpen: isOpenTutorial } = useTutorial();
  const [count, setCount] = useState(0);
  useClickOutSide(ref, onClose);
  const defaultoptions = {
    loop: true,
    autoPlay: true,
    animationData: banner_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (count === 0 && !isOpenTutorial) {
      const timeoutId = setTimeout(() => {
        onOpen();
        setCount((prev) => prev + 1);
      }, 2 * 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpenTutorial]);

  return (
    <section id="AHD" style={{top: 0, position: "absolute", width:"100%", height: "100vh"}}>
      <div
        className={`fixed w-full h-full flex items-center justify-center z-50 transition-transform duration-[2s]  ${
          isOpen
            ? "lg:-translate-y-0 scale-90"
            : "translate-y-[-100vh] scale-0"
        }
      `}
      >
        <div
          className="container box md:p-5 flex flex-col gap-5 relative"
          ref={ref}
        >
          <button
            className="absolute right-3 md:right-5 top-3 md:top-5"
            onClick={onClose}
          >
            <AiOutlineClose size={24} className="cursor-pointer" />
          </button>

          <div className="flex justify-center">
            {/* <Lottie options={defaultoptions} width={600} /> */}
            <img src={Ad_banner} alt="icon" className="hidden md:block" />
            <img
              src={Ad_banner_mb}
              alt="icon"
              className="block md:hidden w-full rounded-2xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ad;
